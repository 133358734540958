@import "/node_modules/modern-css-reset/dist/reset.css";
@import "../layout-components/Center.css";
@import "../layout-components/Center.css";
@import "../layout-components/Cluster.css";
@import "../layout-components/Cover.css";
@import "../layout-components/Imposter.css";
@import "../layout-components/Sidebar.css";
@import "../layout-components/Stack.css";

:root {
  --ratio: 1.4;
  --measure: 90ch;
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
  --s7: calc(var(--s6) * var(--ratio));
  --s8: calc(var(--s7) * var(--ratio));
  font-size: calc(0.33vw + 1em);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  --line-height-small: calc(0.8 * var(--ratio));
}

ul {
  padding: 0;
  list-style: none;
}

button {
  border: none;
}

h1 {
  font-size: calc(var(--s1) + 1.4vw);
  line-height: var(--line-height-small);
}

a {
  color: black;
}

header {
  padding: var(--s0);
}

.navigation li {
}
.navigation a {
  padding: var(--s-2) var(--s1);
  text-decoration: none;
}
.navigation .active {
  background: black;
  color: white;
}

.radio-track {
  display: flex;
}
@media (max-width: 600px) {
  .radio-track {
    display: block;
  }
}
.radio-track .track-info {
  padding: var(--s0) var(--s2);
}
@media (max-width: 600px) {
  .radio-track .track-info {
    padding: var(--s0);
  }
}
.radio-track .track-info > * + * {
  margin-top: var(--s1);
}
@media (max-width: 600px) {
  .radio-track .track-info > * + * {
    margin-top: var(--s-1);
  }
}
@media (max-width: 600px) {
  .radio-track .title {
    font-size: var(--s1);
  }
}

.radio-cover {
  max-width: 400px;
  max-height: 400px;
}
@media (max-width: 600px) {
  .radio-cover {
    padding: 0 var(--s0);
  }
}
.radio-cover {
  width: 100%;
  height: 100%;
}
.radio-cover img {
  height: auto;
}

.radio-track .artist {
  font-size: var(--s1);
  line-height: var(--line-height-small);
}
@media (max-width: 600px) {
  .radio-track .artist {
    font-size: var(--s0);
  }
}

.radio-track .resume-radio {
  background: black;
  color: white;
  padding: var(--s0) var(--s2);
  font-size: var(--s1);
  font-weight: 700;
}

.radio-track.inactive .radio-cover,
.radio-track.inactive .track-info {
  opacity: 0.5;
  filter: blur(5px);
}

.global-player {
  background: white;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--s0);
}

.global-player .controls > * {
  padding: 0 var(--s0);
}
.global-player .controls svg {
  height: var(--s1);
  width: var(--s1);
}

.global-player .cover {
  width: var(--s3);
  max-height: var(--s3);
}
.global-player .track {
  display: flex;
  align-items: center;
}
.global-player .track-info {
  padding: 0 var(--s-2);
}
.global-player .title {
  line-height: var(--line-height-small);
}

.global-player .artist {
  font-size: var(--s-1);
  line-height: var(--line-height-small);
}
.global-player .progress-bar {
  position: absolute;
  top: calc(var(--s-4) * -1);
  left: 0;
  right: 0;
}
.progress-bar .outer {
  height: var(--s-4);
  background: #ddd;
  cursor: pointer;
}
.progress-bar .inner {
  height: var(--s-4);
  background: black;
}

.latest-tracks-page {
  --measure: 110ch;
  padding-bottom: var(--s3);
}

ul.latest-tracks {
  padding-top: var(--s2);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--s7), 1fr));
  grid-gap: var(--s3);
}
.latest-track .track-info {
  padding: var(--s-2);
}
.latest-track .track-info > * {
  line-height: var(--line-height-small);
}
.latest-track .title,
.latest-track .source {
  font-size: var(--s-1);
}
.latest-track .source {
  padding-top: var(--s-4);
}
.latest-tracks .cover {
  position: relative;
  padding-top: 100%;
  background: #eee;
}
.latest-tracks img {
  filter: grayscale(0.5);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
.latest-tracks .selected img,
.latest-tracks img:hover {
  filter: grayscale(0) brightness(1.3);
  cursor: pointer;
  outline: dashed;
}

.queue-tracks .cover img {
  width: var(--s4);
  height: var(--s4);
}

.queues .nav a {
  text-decoration: none;
}
.queues .nav .active a {
  text-decoration: underline;
}
.queue-tracks .artist {
  font-size: var(--s-1);
}
ul.queue-tracks {
  margin-top: var(--s4);
}

.view-more {
  background: black;
  color: white;
  padding: var(--s-1) var(--s1);
  font-weight: 700;
}

.personal-website-link {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: var(--s0);
  font-size: var(--s-1);
}
@media (max-width: 900px) {
  .personal-website-link {
    position: inherit;
    padding-top: 0;
    padding-bottom: var(--s-1);
    text-align: center;
  }
}
